import { reducers as customerIdentificationReducer } from '@evoja-web/customer-identification';
import { reducers as customerWorkguideSearchReducers } from '@evoja-web/customer-workguide-search';
import { reducers as addressDogReducer } from '@evoja-web/react-address-dog-search';
import { reducers as aisDocumentSigningReducers } from '@evoja-web/react-ais-document-signing';
import { reducers as bootstrapReducers } from '@evoja-web/react-bootstrapper';
import { reducers as documentPreviewReducers } from '@evoja-web/react-document-preview';
import { combineReducers } from 'redux';
import { loginReducer, logoutReducer } from '@evoja-web/react-login';
// eslint-disable-next-line import/no-unresolved
import { entrisReducer } from '@evoja-web/entris-portal-shared';
import { reducers as entityCodeReducers } from '@evoja-web/entity-code';
import { reducers as textblockReducers } from '@evoja-web/react-core-textblock';
import { reducers as fileUploadReducers } from '@evoja-web/react-file-upload';
import { reducers as clientSettingReducers } from '@evoja-web/core-clientsetting';
import { reducers as gamificationReducers } from '@evoja-web/gamificator';
import { reducers as applicationReleaseReducers } from '@evoja-web/react-application-release';

import { reducers as accountReducers } from '../packages/Account/index';
import { reducers as activityReducers } from '../packages/Activity/index';
import { reducers as addressReducers } from '../packages/Address/index';
import { reducers as airlockReducers } from '../packages/Airlock/index';
import { reducers as bpfCmsReducers } from '../packages/BpfCms/index';
import { reducers as bpfDocumentReducers } from '../packages/BpfDocument/index';
import { reducers as bpfOnboardingReducers } from '../packages/BpfOnboarding/index';
import { reducers as bpfReducers } from '../packages/Bpf/index';
import { reducers as bpfSecuritiesAccountReducers } from '../packages/BpfSecuritiesAccount/index';
import { reducers as calendarReducers } from '../packages/Calendar/index';
import { reducers as cardReducers } from '../packages/Card/index';
import { reducers as compactableReducer } from '../packages/Compactable/index';
import { reducers as consultationFormReducers } from '../packages/ConsultationForm/index';
import { reducers as contractReducers } from '../packages/Contract/index';
import { reducers as customerBaseDataReducers } from '../packages/CustomerBaseData/index';
import { reducers as customerDetailReducers } from '../packages/CustomerDetail/index';
import { reducers as customerOverviewReducers } from '../packages/CustomerOverview/index';
import { reducers as customerReducers } from '../packages/Customer/index';
import { reducers as dashboardReducers } from '../packages/Dashboard/index';
import { reducers as eDossierReducers } from '../packages/EDossier/index';
import { reducers as enforcementReducers } from '../packages/Enforcement/index';
import { reducers as environmentReducers } from '../packages/Environment/index';
import { reducers as errorReducers } from '../packages/Error/index';
import { reducers as externalLinkReducer } from '../packages/ExternalLinks/index'
import { reducers as featureFlagReducers } from '../packages/FeatureFlag/index';
import { reducers as fileReducers } from '../packages/File/index';
import { reducers as financingReducers } from '../packages/Financing/index';
import { reducers as findentityReducers } from '../packages/Fidentity/index';
import { reducers as finnovaReducers } from '../packages/Finnova/index';
import { reducers as floatingSidebarReducers } from '../packages/FloatingSidebar/index';
import { reducers as infoPopoverReducers } from '../packages/InfoPopover/index';
import { reducers as interestReducers } from '../packages/Interest/index';
import { reducers as leadReducers } from '../packages/Lead/index';
import { reducers as messengerReducers } from '../packages/Messenger/index';
import { reducers as modalReducers } from '../packages/Modal/index';
import { reducers as newsletterReducers } from '../packages/Newsletter/index';
import { reducers as periodicUpdateReducers } from '../packages/PeriodicUpdate/index';
import { reducers as phoneCallReducers } from '../packages/PhoneCall/index';
import { reducers as powerOfAttorneyReducers } from '../packages/PowerOfAttorney/index';
import { reducers as productGroupReducers } from '../packages/ProductGroup/index';
import { reducers as provisionReducers } from '../packages/Provision';
import { reducers as quickFilterReducers } from '../packages/QuickFilter/index';
import { reducers as referencingReducer } from '../packages/Referencing/index';
import { reducers as relationReducers } from '../packages/Relation/index';
import { reducers as releaseNoteReducer } from '../packages/ReleaseNote/index';
import { reducers as selectableReducers } from '../packages/Selectable/index';
import { reducers as setAdvisorReducer } from '../packages/SetAdvisor/index';
import { reducers as spsReducers } from '../packages/Sps/index';
import { reducers as standingOrderReducers } from '../packages/StandingOrder/index';
import { reducers as stockPriceReducers } from '../packages/StockPrice/index';
import { reducers as successorPlanningReducers } from '../packages/SuccessorPlanning/index';
import { reducers as uiNoteReducers } from '../packages/UiNote/index';
import { reducers as vaultsReducers } from '../packages/Vaults/index';
import { reducers as visecaReducers } from '../packages/Viseca/index';
import { reducers as workbenchReducers } from '../packages/Workbench/index';
import { reducers as workguideReducers } from '../packages/Workguide/index';

// Legacy stuff... please move me to packages...
import aclReducer from './AclReducer';
import baseContractReducer from './BaseContractReducer';
import consultantSearchReducers from './ConsultantSearchReducer';
import customerSearchReducer from './CustomerSearchReducer';
import dataLoadStateReducer from './DataLoadStateReducer';
import estimationProtocolReducer from './EstimationProtocolReducer';
import financingEstimationReducer from './FinancingEstimationReducer';
import guidedTourReducer from './GuidedTourReducer';
import setAdvisorReducers from './SetAdvisor';
import toastReducer from './ToastReducer';
import userSettingsReducer from './UserSettingsReducer';
import userSwitchReducer from './UserSwitchReducer';
import userTranckingReducer from './UserTrackingReducer';

export default combineReducers({
  account: accountReducers,
  acl: aclReducer,
  activity: activityReducers,
  address: addressReducers,
  addressDog: addressDogReducer,
  airlock: airlockReducers,
  aisDocumentSigning: aisDocumentSigningReducers,
  applicationRelease: applicationReleaseReducers,
  baseContract: baseContractReducer,
  bootstrap: bootstrapReducers,
  bpf: bpfReducers,
  bpfCms: bpfCmsReducers,
  bpfDocument: bpfDocumentReducers,
  bpfOnboarding: bpfOnboardingReducers,
  calendar: calendarReducers,
  card: cardReducers,
  clientSetting: clientSettingReducers,
  codes: entityCodeReducers,
  compactable: compactableReducer,
  consultantSearch: consultantSearchReducers,
  consultationForm: consultationFormReducers,
  contract: contractReducers,
  customer: customerReducers,
  customerBaseData: customerBaseDataReducers,
  customerDetail: customerDetailReducers,
  customerIdentification: customerIdentificationReducer,
  customerOverview: customerOverviewReducers,
  customerSearch: customerSearchReducer,
  dashboard: dashboardReducers,
  dataLoadState: dataLoadStateReducer,
  documentPreview: documentPreviewReducers,
  eDossier: eDossierReducers,
  externalLinks: externalLinkReducer,
  enforcement: enforcementReducers,
  entris: entrisReducer,
  environment: environmentReducers,
  error: errorReducers,
  estimationProtocol: estimationProtocolReducer,
  featureFlag: featureFlagReducers,
  fidentity: findentityReducers,
  file: fileReducers,
  fileUpload: fileUploadReducers,
  financing: financingReducers,
  financingEstimation: financingEstimationReducer,
  finnova: finnovaReducers,
  floatingSidebar: floatingSidebarReducers,
  gamification: gamificationReducers,
  guidedTour: guidedTourReducer,
  infoPopover: infoPopoverReducers,
  interest: interestReducers,
  lead: leadReducers,
  login: loginReducer,
  logout: logoutReducer,
  messenger: messengerReducers,
  modal: modalReducers,
  newsletter: newsletterReducers,
  periodicUpdate: periodicUpdateReducers,
  phoneCall: phoneCallReducers,
  powerOfAttorney: powerOfAttorneyReducers,
  powersearch: customerWorkguideSearchReducers,
  productGroup: productGroupReducers,
  provision: provisionReducers,
  quickFilter: quickFilterReducers,
  referencing: referencingReducer,
  relation: relationReducers,
  releaseNote: releaseNoteReducer,
  securitiesAccount: bpfSecuritiesAccountReducers,
  selectable: selectableReducers,
  setAdvisor: setAdvisorReducer,
  setadvisor: setAdvisorReducers,
  sps: spsReducers,
  standingOrder: standingOrderReducers,
  stockPrice: stockPriceReducers,
  successorPlanning: successorPlanningReducers,
  textblock: textblockReducers,
  toast: toastReducer,
  uiNote: uiNoteReducers,
  userSettings: userSettingsReducer,
  userSwitch: userSwitchReducer,
  userTracking: userTranckingReducer,
  vaults: vaultsReducers,
  viseca: visecaReducers,
  workbench: workbenchReducers,
  workguide: workguideReducers
});
