import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

// import './SendByPostCreateCoverLetter.scss';
import { get, isEmpty, noop } from 'lodash';
import { bindActionCreators } from 'redux';
import spsActions from '../../actions/Actions';
import { getValidator } from '../../../../globals';
import { WorkguideCustomerAware } from '../../../Workguide';
import CreateCoverLetterForm, { getValidationDefinition } from '../../components/SendByPost/CreateCoverLetterForm';

const shouldDisable = (validations) => {
  return !isEmpty(validations);
};

class CreateCoverLetter extends PureComponent {
  constructor(props) {
    super(props);

    this.initForm = this.initForm.bind(this);
    this.submit = this.submit.bind(this);
  }

  initForm() {
    const {
      spsActions,
      customer
    } = this.props;

    spsActions.createCoverLetterWorkguideFormInitRequest({
      customer
    });
  }

  /**
   * Validate the current data
   *
   * @return {Object} result Validation result
   */
  validate() {
    const { data } = this.props;
    const validator = getValidator();

    const CreateCoverLetterForValidationDef = getValidationDefinition();

    const definition = {
      validations: {
        ...CreateCoverLetterForValidationDef,
      }
    };

    return validator.validate(definition, data);
  }

  submit(validations) {
    const {
      spsActions, customer, data, language, intl, session, toggleRedirectModal
    } = this.props;

    if (!shouldDisable(validations)) {
      spsActions.createCoverLetterWorkguideFormSaveRequest({ customer, data, language, intl, consultant: session });
    }

    toggleRedirectModal();
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      customerAddresses, data, fulfilled, spsActions, language, onFormCancel
    } = this.props;

    const validationResult = this.validate();

    return (
      <WorkguideCustomerAware
        onChange={this.initForm}
        onInit={this.initForm}
        requesting={!fulfilled}
        showLoadIndicator
      >
        <CreateCoverLetterForm
          customerAddresses={customerAddresses}
          data={data}
          fulfilled={fulfilled}
          language={language}
          showValidations
          validations={validationResult}
          onChange={spsActions.createCoverLetterWorkguideFormSetValue}
          withoutReplyLetter
        />

        <div className="mt-5">
          <Button
            color="primary"
            onClick={() => this.submit(validationResult)}
            disabled={shouldDisable(validationResult)}
          >
            <FormattedMessage id="BpfDocument.SendByPost.CreateCoverLetterForm.ShowPreview" />
          </Button>
          <Button
            color="primary"
            onClick={onFormCancel}
            style={{ marginLeft: '20px' }}
          >
            <FormattedMessage id="BpfDocument.SendByPost.CreateCoverLetterForm.Close" />
          </Button>
        </div>
      </WorkguideCustomerAware>
    );
  }
}

CreateCoverLetter.propTypes = {
  spsActions: PropTypes.object.isRequired,
  customer: PropTypes.object,
  customerAddresses: PropTypes.array,
  intl: PropTypes.object.isRequired,
  data: PropTypes.object,
  fulfilled: PropTypes.bool,
  language: PropTypes.string,
  session: PropTypes.object.isRequired,
  toggleRedirectModal: PropTypes.func,
  onFormCancel: PropTypes.func
};

CreateCoverLetter.defaultProps = {
  customer: undefined,
  customerAddresses: [],
  data: {},
  language: 'de',
  fulfilled: false,
  toggleRedirectModal: noop,
  onFormCancel: noop
};

function isFulfilled(state) {
  return (
    get(state, 'address.customerAddresses.fulfilled', false)
    || get(state, 'address.createCoverLetterWorkguideForm.initialized', false)
  );
}

function mapStateToProps(state, ownProps) {
  const customer = get(ownProps, 'customer');

  return {
    customer,
    data: get(state, 'sps.createCoverLetterWorkguideForm.data', {}),
    fulfilled: isFulfilled(state),
    customerAddresses: get(state, 'address.customerAddresses.data'),
    language: state.login.language,
    session: state.login.session
  };
}

function mapDispatchToProps(dispatch) {
  return {
    spsActions: bindActionCreators(spsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateCoverLetter));
