import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, noop, isUndefined, head, isEmpty } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row, Col, Label, FormGroup, Button } from 'reactstrap';
import { actions as codeActions, utils as codeUtils, withCodeGroups, withCodeMapper } from '@evoja-web/entity-code';
import { Alert, AlertContent } from '@evoja-web/react-layout-components';

import { actions as textblockActions } from '@evoja-web/react-core-textblock';
import { getTranslationKey } from '../../../../staticMappings/mapVisecaCodes';
import { getValidator } from '../../../../globals';
import Textblock from '../../../../components/General/Textblock';
import { AuthorizedCustomer, SuspensionReason, SuspensionStatus } from '../../components/Card/Form/Fields';
import { cardStatusOptions } from './Suspensions';
import { WorkguideCustomerAware, withWorkguideInstanceData } from '../../../Workguide';
import withCustomerCardData from '../../hocs/withCustomerCardData';
import cardActions from '../../actions/Actions';
import WithCardSelect from '../../components/Card/Form/WithCardSelect';
import { cardTypeMap } from '../../../../staticMappings/cards';
import { Select } from '../../../General';

const validator = getValidator();

const cardTypeToTextblockId = {
  dmc: 'creditCardClaimInfoDebitMasterCard',
  credit: 'creditCardClaimInfoCreditCard',
  maestro: {
    fraud: 'creditCardClaimInfoMaestroBankCardFraud',
    chargeback: 'creditCardClaimInfoMaestroBankCardChargeBack'
  },
  bank: {
    fraud: 'creditCardClaimInfoMaestroBankCardFraud',
    chargeback: 'creditCardClaimInfoMaestroBankCardChargeBack'
  },
  other: 'creditCardClaimInfoOthers'
};

const claimTypeOptions = [{
  value: 'fraud',
  label: <FormattedMessage id="Card.Claim.Type.Fraud" />
}, {
  value: 'chargeback',
  label: <FormattedMessage id="Card.Claim.Type.ChargeBack" />
}];

class CreditCardClaim extends React.Component {
  constructor(props) {
    super(props);

    const {
      codeActions,
      textblockActions
    } = props;

    this.state = {
      selected: undefined,
      authorizedCustomer: undefined,
      status: 'suspended_by_bank',
      reason: undefined,
      claimType: undefined
    };

    codeActions.groupsRequest({ groups: ['cardSuspension'] });
    textblockActions.outputRequest({ dataKey: 'workguide-creditCardClaim' });

    this.setSuspensionCode();

    this.onCustomerChange = this.onCustomerChange.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { codes } = this.props;
    const { codes: prevCodes } = prevProps;

    const suspensionCodes = get(codes, 'groups.cardSuspension');
    const prevSuspensionCodes = get(prevCodes, 'groups.cardSuspension');

    if (suspensionCodes !== prevSuspensionCodes) {
      this.setSuspensionCode();
    }
  }

  /**
   * Handle customer change in instance data
   *
   * @param   {Object}  customer  Customer from /person/customer
   *
   * @return  void
   */
  onCustomerChange(customer) {
    const { cardActions } = this.props;

    cardActions.cardAuthorizationsRequest({ customerId: get(customer, 'id') });
  }

  onValueChange(id, value) {
    this.setState({ [id]: value });

    if (id === 'selected') this.setState({ claimType: undefined });
  }

  onSubmit() {
    const {
      selected,
      status,
      reason,
      authorizedCustomer
    } = this.state;

    const {
      cardActions,
      customer,
      intl,
      language,
      workguide,
      session,
      redirect,
      cardInfo
    } = this.props;

    const card = head(selected);
    const cardDeatils = get(cardInfo, `cardDetails.${get(head(selected), 'id')}.data`);
    let note = `<strong>${get(workguide, `title.${language}`)}</strong> \n`;
    note = ` \
      ${note} \
      ${get(card, 'cardNumber')} \
      ${' - '} \
      ${get(card, `cardType.text.${language}`)}
    `;

    note = ` \
      ${note} \
      ${intl.formatMessage({ id: 'Card.Suspension.Cards' })}: \
      ${' '} \
      ${get(head(selected), `cardType.text.${language}`)} \
      ${' '} \
      (${get(head(selected), 'cardNumber')})
    `;

    note = `${note} ${intl.formatMessage({ id: 'Card.SuspensionCode' })}: ${intl.formatMessage({ id: getTranslationKey('cardStatusMap', status) })} \n`;
    note = `${note} ${intl.formatMessage({ id: 'Card.Suspension.Reason' })}: ${get(reason, `text.${language}`, '')} \n`;
    note = ` \
      ${note} \
      ${intl.formatMessage({ id: 'Card.Client' })}: \
      ${' '} \
      ${get(authorizedCustomer, 'designation')} \
    `;

    cardActions.saveCardSuspensionRequest({
      card,
      cardDeatils,
      status,
      reason,
      customer,
      consultant: session,
      formData: this.state,
      note,
      workguide
    });
    redirect();
  }

  getCardTypeKey() {
    const { selected } = this.state;
    const { mapper } = this.props;

    const cardTypeId = get(head(selected), 'cardType.id');
    if (mapper.get('cardTypeMap', 'maestro').includes(cardTypeId)) return 'maestro';
    if (mapper.get('cardTypeMap', 'bank').includes(cardTypeId)) return 'bank';
    if (mapper.get('cardTypeMap', 'dmc').includes(cardTypeId)) return 'dmc';
    if (mapper.get('cardTypeMap', 'credit').includes(cardTypeId)) return 'credit';

    return 'other';
  }

  setSuspensionCode() {
    const { codes } = this.props;

    const suspensionCodes = get(codes, 'groups.cardSuspension', []);
    const reason = suspensionCodes.find((code) => get(code, 'id') === 'cardSuspension-54');

    this.setState({ reason });
  }

  validate() {
    const {
      selected,
      authorizedCustomer,
      reason,
      status
    } = this.state;

    const definition = {
      validations: {
        selected: {
          type: 'array',
          required: true
        },
        authorizedCustomer: {
          type: 'object',
          required: true
        },
        reason: {
          type: 'object',
          required: true
        },
        status: {
          type: 'string',
          required: true
        }
      }
    };

    return validator.validate(definition, { selected, authorizedCustomer, reason, status });
  }

  isUidErroneous() {
    const { cardInfo } = this.props;
    const { selected } = this.state;
    const card = head(selected);

    const details = get(cardInfo, `cardDetails.${card?.id}.data`);

    return isUndefined(get(details, 'uid')) || get(card, 'detailsuid') instanceof Error;
  }

  isSubmitDisabled() {
    const cardTypeKey = this.getCardTypeKey();
    return (!['maestro', 'bank'].includes(cardTypeKey) && this.isUidErroneous());
  }

  renderTextblock(cardTypeKey, claimType) {
    const { selected } = this.state;
    const {
      language,
      textblocks
    } = this.props;

    if (isUndefined(selected)) return null;
    let textblockId = get(cardTypeToTextblockId, cardTypeKey);
    if (!isUndefined(claimType)) textblockId = get(textblockId, claimType);

    const text = get(
      get(textblocks, 'data', []).find((block) => get(block, 'id') === textblockId),
      `text.${language}`,
      ''
    );

    return (
      <Alert type="warning">
        <AlertContent>
          <Textblock>
            {text}
          </Textblock>
        </AlertContent>
      </Alert>
    );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      authorizedCustomer,
      selected,
      reason,
      status,
      claimType
    } = this.state;

    const {
      authorizations,
      codes,
      language,
      redirect,
      cardInfo,
      customer
    } = this.props;

    const cardTypeKey = this.getCardTypeKey();
    const validations = this.validate();
    const suspensionCodes = get(codes, 'groups.cardSuspension', []);
    return (
      <WorkguideCustomerAware onInit={this.onCustomerChange} onChange={this.onCustomerChange}>
        <WithCardSelect
          onCardSelected={this.onValueChange}
          selected={selected}
          cardInfo={cardInfo}
          validations={validations}
          customer={customer}
          disabled={[]}
        />

        {!isEmpty(selected) && this.isUidErroneous() && (
        <Alert
          type="error"
          title={<FormattedMessage id="Card.Error.UidError.Title" />}
          escription={<FormattedMessage id="Card.Error.UidError.Description" />}
        />
        )}

        {['maestro', 'bank'].includes(cardTypeKey) && (
        <FormGroup>
          <Label>
            <FormattedMessage id="Card.Claim.Type.Title" />
          </Label>

          <Select
            id="claimType"
            name="claimType"
            options={claimTypeOptions}
            value={claimType}
            onChange={this.onValueChange}
          />
        </FormGroup>
        )}

        {!isUndefined(selected) && ['dmc', 'credit', 'other'].includes(cardTypeKey) && (
        <Row>
          <Col lg={12} md={12}>
            {this.renderTextblock(cardTypeKey, claimType)}
          </Col>
        </Row>
        )}

        {!isUndefined(selected) && ['maestro', 'bank'].includes(cardTypeKey) && !isUndefined(claimType) && (
        <Row>
          <Col lg={12} md={12}>
            {this.renderTextblock(cardTypeKey, claimType)}
          </Col>
        </Row>
        )}

        {['dmc', 'credit'].includes(cardTypeKey) && (
        <React.Fragment>
          <Row style={{ paddingBottom: '20px' }}>
            <Col lg={12} md={12}>
              <SuspensionStatus
                value={status}
                options={cardStatusOptions}
                onChange={this.onValueChange}
                validations={validations.status}
                disabled
              />
            </Col>
          </Row>

          <Row style={{ paddingBottom: '20px' }}>
            <Col lg={12} md={12}>
              <SuspensionReason
                value={reason}
                codes={suspensionCodes}
                onChange={this.onValueChange}
                validations={validations.reason}
                language={language}
                disabled
              />
            </Col>
          </Row>

          <Row style={{ paddingBottom: '15px' }}>
            <Col lg={12} md={12}>
              <AuthorizedCustomer
                authorizations={get(authorizations, 'data', [])}
                value={get(authorizedCustomer, 'id')}
                validations={validations.authorizedCustomer}
                onChange={this.onValueChange}
              />
            </Col>
          </Row>

          {['dmc', 'credit'].includes(cardTypeKey) && (
          <Row style={{ paddingTop: '10px' }}>
            <Col lg={12} md={12}>
              <Button color="primary" onClick={this.onSubmit} disabled={this.isSubmitDisabled() || !isEmpty(validations)}>
                <FormattedMessage id="Card.Button.SetSuspension" />
              </Button>

              <span style={{ paddingLeft: '10px' }} />

              <Button color="primary" outline onClick={redirect}>
                <FormattedMessage id="Card.Button.Cancel" />
              </Button>
            </Col>
          </Row>
          )}
        </React.Fragment>
        )}
      </WorkguideCustomerAware>
    );
  }
}

CreditCardClaim.propTypes = {
  authorizations: PropTypes.object,
  cardActions: PropTypes.object.isRequired,
  codeActions: PropTypes.object.isRequired,
  codes: PropTypes.object,
  customer: PropTypes.object,
  intl: PropTypes.object.isRequired,
  language: PropTypes.string,
  mapper: PropTypes.object.isRequired,
  redirect: PropTypes.func,
  session: PropTypes.object.isRequired,
  // prop from higher order component
  cardInfo: PropTypes.object,
  textblockActions: PropTypes.object.isRequired,
  textblocks: PropTypes.object,
  workguide: PropTypes.object
};

CreditCardClaim.defaultProps = {
  authorizations: {},
  codes: { groups: {} },
  customer: undefined,
  language: 'de',
  redirect: noop,
  cardInfo: undefined,
  textblocks: {},
  workguide: undefined
};

function isRequesting(state) {
  return (
    get(state, 'textblock.output.workguide-creditCardClaim.requesting', false)
    || get(state, 'card.authorizations.requesting', false)
    || !codeUtils.allGroupsFulfilledOrRejected(state.codes)
  );
}

function mapStateToProps(state) {
  const { codes, login } = state;

  return {
    language: login.language,
    session: login.session,
    codes,
    suspend: state.card.suspend,
    requesting: isRequesting(state),
    authorizations: state.card.authorizations,
    textblocks: get(state, 'textblock.output.workguide-creditCardClaim')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    cardActions: bindActionCreators(cardActions, dispatch),
    codeActions: bindActionCreators(codeActions, dispatch),
    textblockActions: bindActionCreators(textblockActions, dispatch)
  };
}

export default withWorkguideInstanceData()(

  withCodeGroups({ groups: ['cardSuspension'] })(
    // fetch customer cards with described details
    withCustomerCardData({
      details: ['status'],
      excludedCardTypes: ['cardType-23'],
      allowedCardTypes: [...cardTypeMap.get('credit'), ...cardTypeMap.get('dmc')]
    })(
      connect(mapStateToProps, mapDispatchToProps)(
        withCodeMapper()(injectIntl(CreditCardClaim))
      )
    )
  )
);
