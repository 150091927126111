import { call, put } from 'redux-saga/effects';

import axios from 'axios';
import provisionAccountsAction from '../actions/ProvisionAccounts';
import { removeUnwantedValues, toIntIfSet } from '../../../lib/Utils';

const {
  PROVISION_ACCOUNTS_PENDING,
  PROVISION_ACCOUNTS_FULFILLED,
  PROVISION_ACCOUNTS_REJECTED
} = provisionAccountsAction;

export default function* getProvisionAccounts(request) {
  const { dataKey, tenant = 6300 } = request;

  yield put({ type: PROVISION_ACCOUNTS_PENDING, dataKey });

  try {
    const url = `/sif/bpf/public/api/v1/process-definition/key/valiant-depot-loadPensionAccountsV1/tenant-id/${tenant}/execute`;
    const data = {
      variables: {
        clientKey: toIntIfSet(dataKey)
      },
      businessKey: 'valiant-product-loadPensionAccountsV1',
      resultVariableNames: [
        'pensionProducts'
      ],
      waitTimeoutInMs: 40000
    };

    const { data: payload } = yield call(axios, {
      url,
      method: 'post',
      data: removeUnwantedValues(data)
    });

    yield put({ type: PROVISION_ACCOUNTS_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: PROVISION_ACCOUNTS_REJECTED, dataKey, error });

    return error;
  }
}
