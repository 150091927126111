import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { get, head, isEmpty, noop } from 'lodash';
import { withCodeGroups, utils as codeUtils } from '@evoja-web/entity-code';
import { Button, Col, Row } from 'reactstrap';
import cardActions from '../../actions/Actions';
import { withWorkguideInstanceData } from '../../../Workguide/hocs';
import WithCardSelect from '../../components/Card/Form/WithCardSelect';
import { SuspensionReason, SuspensionStatus } from '../../components/Card/Form/Fields';
import { getTranslationKey } from '../../../../staticMappings/mapVisecaCodes';
import { filterCardSuspensionCodes, filterCardStatusOptions } from '../../lib/Suspensions';
import { getValidator } from '../../../../globals';
import withCustomerCardData from '../../hocs/withCustomerCardData';
import { cardTypeMap } from '../../../../staticMappings/cards';

export const cardStatusOptions = [{
  value: 'active',
  label: <FormattedMessage id={getTranslationKey('cardStatusMap', 'active')} />
}, {
  value: 'blocked',
  label: <FormattedMessage id={getTranslationKey('cardStatusMap', 'blocked')} />
}, {
  value: 'suspended_by_cardholder',
  label: <FormattedMessage id={getTranslationKey('cardStatusMap', 'suspended_by_cardholder')} />
}, {
  value: 'suspended_by_bank',
  label: <FormattedMessage id={getTranslationKey('cardStatusMap', 'suspended_by_bank')} />
}, {
  value: 'suspended_by_issuer',
  label: <FormattedMessage id={getTranslationKey('cardStatusMap', 'suspended_by_issuer')} />
}];

class CardSuspension extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      status: undefined,
      reason: undefined
    };

    this.onValueChange = this.onValueChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * Handle onChange of one of the form fields
   *
   * @param  {String} prop  Property that changed
   * @param  {Mixed}  value Value
   *
   * @return void
   */
  onValueChange(prop, value) {
    this.setState({ [prop]: value });
  }

  /**
   * Form submit
   *
   * @return void
   */
  onSubmit() {
    const {
      selected,
      status,
      reason
    } = this.state;

    const {
      cardActions,
      customer,
      intl,
      language,
      session,
      workguide,
      redirect,
      cardInfo
    } = this.props;

    const cardDeatils = get(cardInfo, `cardDetails.${get(head(selected), 'id')}.data`);
    let note = `<strong>${get(workguide, `title.${language}`)}</strong> \n`;
    note = ` \
        ${note} \
        ${intl.formatMessage({ id: 'Card.Suspension.Cards' })}: \
        ${' '} \
        ${get(head(selected), `cardType.text.${language}`)} \
        ${' '} \
        (${get(head(selected), 'cardNumber')})
      `;

    note = `${note} ${intl.formatMessage({ id: 'Card.SuspensionCode' })}: ${intl.formatMessage({ id: getTranslationKey('cardStatusMap', status) })} \n`;
    if (status !== 'active') note = `${note} ${intl.formatMessage({ id: 'Card.Suspension.Reason' })}: ${get(reason, `text.${language}`, '')}`;

    cardActions.saveCardSuspensionRequest({
      card: head(selected),
      cardDeatils,
      status,
      reason,
      customer,
      consultant: session,
      formData: this.state,
      note,
      workguide
    });
    redirect();
  }

  /**
   * Check if the reason field is required
   *
   * @return {Boolean}
   */
  isReasonRequired() {
    const { status } = this.state;

    return ['suspended_by_cardholder', 'suspended_by_bank'].includes(status);
  }

  /**
   * Validate
   *
   * @return {Object} validations Validation result
   */
  validate() {
    const definition = {
      validations: {
        selected: {
          type: 'array',
          required: true
        },
        status: {
          type: 'string',
          required: true
        },
        reason: {
          type: 'object',
          required: this.isReasonRequired()
        }
      }
    };

    const validator = getValidator();

    return validator.validate(definition, this.state);
  }

  render() {
    const { selected, status, reason } = this.state;
    const {
      redirect, language, codes, customer, cardInfo
    } = this.props;

    const details = get(cardInfo, `cardDetails.${get(head(selected), 'id')}.data`);
    const suspensionCodes = get(codes, 'groups.cardSuspension', []);
    const validations = this.validate();
    return (
      <>
        <WithCardSelect
          onCardSelected={this.onValueChange}
          selected={selected}
          cardInfo={cardInfo}
          validations={validations}
          customer={customer}
        />
        <Row style={{ paddingBottom: '20px' }}>
          <Col lg={12} md={12}>
            <SuspensionStatus
              value={status}
              options={filterCardStatusOptions(get(details, 'status.status'), cardStatusOptions)}
              onChange={this.onValueChange}
              validations={validations.status}
            />
          </Col>
        </Row>

        {this.isReasonRequired() ? (
          <Row style={{ paddingBottom: '20px' }}>
            <Col lg={12} md={12}>
              <SuspensionReason
                value={reason}
                codes={filterCardSuspensionCodes(suspensionCodes)}
                onChange={this.onValueChange}
                validations={validations.reason}
                language={language}
              />
            </Col>
          </Row>
        ) : null}

        <Row style={{ paddingTop: '10px' }}>
          <Col lg={12} md={12}>
            <Button color="primary" onClick={this.onSubmit} disabled={isEmpty(selected) || isEmpty(status)}>
              <FormattedMessage id={status === 'active' ? 'Card.Button.RemoveSuspension' : 'Card.Button.SetSuspension'} />
            </Button>

            <span style={{ paddingLeft: '10px' }} />

            <Button color="primary" outline onClick={redirect}>
              <FormattedMessage id="Card.Button.Cancel" />
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}
CardSuspension.propTypes = {
  cardActions: PropTypes.object.isRequired,
  codes: PropTypes.object,
  customer: PropTypes.object,
  intl: PropTypes.object.isRequired,
  language: PropTypes.string,
  redirect: PropTypes.func,
  // prop from higher order component
  cardInfo: PropTypes.object,
  session: PropTypes.object.isRequired,
  workguide: PropTypes.object,
};

CardSuspension.defaultProps = {
  codes: { groups: {} },
  customer: undefined,
  language: 'de',
  redirect: noop,
  cardInfo: {},
  workguide: undefined
};

function mapStateToProps(state) {
  const { login, codes } = state;
  return {
    language: login.language,
    codes,
    session: login.session,
    suspend: state.card.suspend,
    requesting: !codeUtils.allGroupsFulfilledOrRejected(state.codes)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    cardActions: bindActionCreators(cardActions, dispatch)
  };
}

export default withWorkguideInstanceData()(

  withCodeGroups({ groups: ['cardSuspension'] })(
    // fetch customer cards with described details
    withCustomerCardData({ details: ['info', 'status'], allowedCardTypes: cardTypeMap.get('credit') })(
      connect(mapStateToProps, mapDispatchToProps)(
        injectIntl(CardSuspension)
      )
    )
  )
);
