import { call, put } from 'redux-saga/effects';
import { get, noop } from 'lodash';
import { removeHost } from '@evoja-web/redux-saga-utils';
import { getIdFromGravitonUrl } from '@evoja-web/react-document-preview';
import actions from '../actions/Actions';
import getDocument from './getDocument';
import mergeDocuments from './mergeDocuments';

const {
  PRINT_DOCUMENTS_PENDING,
  PRINT_DOCUMENTS_FULFILLED,
  PRINT_DOCUMENTS_REJECTED
} = actions;

export default function* printDocuments(request) {
  const { documents, onFinish = noop } = request;

  yield put({ type: PRINT_DOCUMENTS_PENDING });

  try {
    const documentIds = documents.map((doc) => {
      const path = removeHost(get(doc, 'docLink', ''));
      return getIdFromGravitonUrl(path);
    });

    let fileIdToGet;

    if (get(documentIds, 'length') > 1) {
      // Merge Files
      const mergedFile = yield call(mergeDocuments, { fileIds: documentIds, returnFile: false });

      fileIdToGet = mergedFile.fileId;
    } else {
      fileIdToGet = get(documentIds, '0');
    }

    const file = yield call(getDocument, { dataKey: fileIdToGet });

    onFinish(file, documents);
    yield put({ type: PRINT_DOCUMENTS_FULFILLED, payload: file });

    return file;
  } catch (error) {
    yield put({ type: PRINT_DOCUMENTS_REJECTED, error });

    return error;
  }
}
