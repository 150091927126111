import React, { lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';
// eslint-disable-next-line
import { ProvisionLeadDetail, ProvisionLeadLetterPreview } from '@evoja-web/entris-portal-shared';

import PrivateRoute from './components/Router/PrivateRoute';
import NoMatch from './components/Router/NoMatch';

// import Layout from './containers/Layout';
import Layout from './packages/General/containers/Layout/Layout';
import UserSwitchRoute from './components/Router/UserSwitchRoute';

// lazy routes
const WorkguideEdit = lazy(() => import('./packages/Workguide/containers/EditWorkguide'));
const ActivityReload = lazy(() => import('./containers/ActivityReload'));
const OpenActivity = lazy(() => import('./packages/Activity/containers/OpenActivity'));
const ConsultantLeadQualification = lazy(() => import('./packages/Lead/containers/Qualification/Consultant/Board'));
const CustomerDetail = lazy(() => import('./packages/CustomerDetail/containers/Detail'));
const CustomerLeadQualification = lazy(() => import('./packages/Lead/containers/Qualification/Customer/Board'));
const CustomerOverview = lazy(() => import('./packages/CustomerOverview/containers/CustomerOverview'));
const CustomerSearch = lazy(() => import('./containers/CustomerSearch'));
const Dashboard = lazy(() => import('./packages/Dashboard/containers/Dashboard'));
const ErrorOverview = lazy(() => import('./packages/Error/containers/Overview'));
const EstimationProtocol = lazy(() => import('./containers/EstimationProtocol'));
const FinancingEstimation = lazy(() => import('./containers/FinancingEstimation'));
const LeadManagement = lazy(() => import('./packages/Lead/containers/Board/Consultant/Board'));
const OpenWorkguide = lazy(() => import('./packages/Workguide/containers/OpenWorkguide'));
const PdfPreview = lazy(() => import('./components/Pdf/Preview'));
const PhoneCall = lazy(() => import('./packages/PhoneCall/containers/CustomerOverview'));
const ProvisionLeadOverview = lazy(() => import('./containers/ProvisionLeadOverview'));
const ReachingAgeLimitDocs = lazy(() => import('./components/Pdf/ReachingAgeLimitDocs'));
const Referencing = lazy(() => import('./packages/Referencing/containers/Search'));
const VaultsOverview = lazy(() => import('./packages/Vaults/containers/VaultsOverview/VaultsOverview'));
const WorkbenchOverview = lazy(() => import('./packages/Workbench/containers/Overview/Overview'));
const WorkguideMailCallback = lazy(() => import('./packages/Workguide/containers/WorkguideMailCallback'));
const WorkguideWrapper = lazy(() => import('./packages/Workguide/containers/WorkguideWrapper'));
const Test = lazy(() => import('./containers/Test'));

function Routes() {
  return (
    <Layout>
      <Suspense fallback={<div />}>
        <Switch>
          <PrivateRoute exact path="/activity/open/:activityId" component={OpenActivity} />
          {/* Confusing naming... activity is acutally an activity that belongs to a workguide */}
          <PrivateRoute exact path="/activity/:activityId/:customerId?/:existingWorkguideId?" component={WorkguideWrapper} />
          <PrivateRoute exact path="/edit/activity/:activityId/:workguideId?/:customerId?" component={WorkguideEdit} />
          {/* Activity is this case is a note (activity without a relation to a workguide) */}
          <PrivateRoute exact path="/reload/activity/:activityId" component={ActivityReload} />
          <PrivateRoute exact path="/call/:phoneNumber" component={PhoneCall} />
          <PrivateRoute exact path="/customer/:customerNumber/:page?" component={CustomerDetail} />
          <PrivateRoute exact path="/customeroverview" component={CustomerOverview} />
          <PrivateRoute exact path="/customerreference" component={Referencing} />
          <PrivateRoute exact path="/customersearch/:searchTerm" component={CustomerSearch} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/error/overview" component={ErrorOverview} />
          <PrivateRoute exact path="/estimationprotocol" component={EstimationProtocol} />
          <PrivateRoute exact path="/financingestimation" component={FinancingEstimation} />
          <PrivateRoute exact path="/leadmanagement" component={LeadManagement} />
          <PrivateRoute exact path="/leadmanagement/:leadId/:customerNumber" component={CustomerDetail} />
          <PrivateRoute exact path="/leadqualification/consultant" component={ConsultantLeadQualification} />
          <PrivateRoute exact path="/leadqualification/customer/:customerId" component={CustomerLeadQualification} />
          <PrivateRoute exact path="/preview/pdf/:ref" component={PdfPreview} />
          <PrivateRoute exact path="/provision/leadmanagement" component={ProvisionLeadOverview} />
          <PrivateRoute exact path="/provisionLead/:provisionLeadId" component={ProvisionLeadDetail} />
          <PrivateRoute exact path="/provisionLead/:provisionLeadId/letterPreview" component={ProvisionLeadLetterPreview} />
          <PrivateRoute exact path="/reachingAgeLimit/:letterType/:product/:customerId/:accountNumber?" component={ReachingAgeLimitDocs} />
          <PrivateRoute exact path="/vaultsoverview" component={VaultsOverview} />
          <PrivateRoute exact path="/workbench/load/process/:processInstanceId/:workguideId/:customerId" component={OpenWorkguide} />
          <PrivateRoute exact path="/workbench/load/task/:taskType/:taskId/:workguideId/:customerId" component={OpenWorkguide} />
          <PrivateRoute exact path="/workbench/overview" component={WorkbenchOverview} />
          <PrivateRoute exact path="/workguide/mailCallback/:activityId/:statusId/:customerId?" component={WorkguideMailCallback} />
          <PrivateRoute exact path="/customerreference" component={Referencing} />
          <PrivateRoute exact path="/ruedi-wants-to-talk" component={Test} />
          <UserSwitchRoute exact path="/dashboard/share/:consultant" component={Dashboard} />
          <PrivateRoute component={NoMatch} />
        </Switch>
      </Suspense>
    </Layout>
  );
}

export default Routes;
