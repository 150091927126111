import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { DocumentPreviewModal } from '@evoja-web/react-document-preview';
import { WelcomePage, GamificatorOverlay } from '@evoja-web/gamificator';
import { withAcl } from '@evoja-web/client-acl';
import { Bootstrap, BootstrapContext } from '@evoja-web/react-bootstrapper';

import './Layout.css';
import { Modals as LeadModals } from '../../../Lead/index';
import Header from './Header/Header';
import {
  WorkguideModal,
  RejectReasonModal as WorkguideRejectReasonModal,
  WorkguidePostActionsSummary
} from '../../../Workguide/index';
import { ReloadModal } from '../../../Modal/index';
import { Banners } from '../../../UiNote/index';
import checkShowGamification from '../../../../lib/Gamification/checkShowGamification';
import { bootstrapCodes, bootstrapCodeMappings } from '../../../../sagas/Bootstrap/index';
import DataError from '../../../../components/Bootstrap/DataError';

const bootstrapContext = BootstrapContext()
  .required({ id: 'codeMappings', saga: bootstrapCodeMappings })
  .required({ id: 'codes', saga: bootstrapCodes });

class Layout extends React.PureComponent {
  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      children,
      intl,
      session,
      userSwitch,
      acl,
      aclUserRolesFulfilled
    } = this.props;

    const withGamification = aclUserRolesFulfilled && checkShowGamification(acl);

    const headerText = `MAP - ${get(userSwitch, 'selected')
      ? `${get(userSwitch, 'selected.firstName')} ${get(userSwitch, 'selected.lastName')} `
      : `${get(session, 'firstName')} ${get(session, 'lastName')}`}`;

    return (
      <Bootstrap
        id="layoutBootstrap"
        context={bootstrapContext}
        errorComponent={DataError}
      >
        <div className="layout">
          <Helmet>
            <title>{headerText}</title>
          </Helmet>

          <Header />

          <Banners />

          {
            withGamification
            && (
              <>
                <WelcomePage />
                <GamificatorOverlay />
              </>
            )
          }

          <Container
            id="layout-content"
            className="layout-content"
            fluid
          >
            {children}

            <DocumentPreviewModal
              errorDescription={intl.formatMessage({ id: 'DocumentPreview.Error.Description' })}
              errorTitle={intl.formatMessage({ id: 'DocumentPreview.Error.Title' })}
            />
            <WorkguideModal />
            <ReloadModal dataKey="workguide">
              <WorkguidePostActionsSummary />
            </ReloadModal>
            <ReloadModal dataKey="workbench" />
            <WorkguideRejectReasonModal />
            <LeadModals />
          </Container>
        </div>
      </Bootstrap>
    );
  }
}

Layout.propTypes = {
  acl: PropTypes.object.isRequired,
  children: PropTypes.node,
  intl: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  userSwitch: PropTypes.object,
  aclUserRolesFulfilled: PropTypes.bool
};

Layout.defaultProps = {
  children: [],
  userSwitch: {},
  aclUserRolesFulfilled: false
};

function mapStateToProps(state) {
  return {
    session: state.login.session,
    userSwitch: state.userSwitch,
    aclUserRolesFulfilled: state.acl.userRoles.fulfilled
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({}, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withAcl()(
    injectIntl(Layout)
  )
);
