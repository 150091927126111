import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import parseLinkHeader from 'parse-link-header';
import { get, isUndefined } from 'lodash';
import { removeHost } from '@evoja-web/redux-saga-utils';
import { actions as gamificatorActions } from '@evoja-web/gamificator';

import {
  NOTIFICATION_MAIL_PENDING,
  NOTIFICATION_MAIL_FULFILLED,
  NOTIFICATION_MAIL_REJECTED
} from '../../actions/Notification';
import wrapPollRequest from '../EventStatus/wrapPollRequest';

const { TRIGGER_ACTION_REQUEST } = gamificatorActions;
export default function* sendMail({
  recipient,
  data,
  onSuccess,
  onTimeout,
  onFailed,
  onError
}) {
  yield put({ type: NOTIFICATION_MAIL_PENDING, recipient, data });

  try {
    const eventStatus = yield call(axios, {
      url: '/work/notification',
      method: 'post',
      data: { ...data, recipient }
    });

    const linkHeaders = parseLinkHeader(eventStatus.headers.link);
    const url = get(linkHeaders, 'eventStatus.url');

    const result = yield call(
      wrapPollRequest,
      {
        url: removeHost(url),
        onSuccess,
        onFailed,
        onTimeout,
        onError
      }
    );

    yield put({ type: NOTIFICATION_MAIL_FULFILLED, result, recipient, data });
    yield put({ type: TRIGGER_ACTION_REQUEST, dataKey: 'emailDocuments' });
    return result;
  } catch (error) {
    yield put({ type: NOTIFICATION_MAIL_REJECTED, error, recipient, data, suppressToast: true });
    if (!isUndefined(onError)) yield call(onError, error);

    throw error;
  }
}
