import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get } from 'lodash';
import { encodeRqlString } from '@evoja-web/redux-saga-utils';
import actions from '../actions/Actions';

const {
  DOCUMENT_PENDING,
  DOCUMENT_FULFILLED,
  DOCUMENT_REJECTED
} = actions;

export default function* getDocument(request) {
  const { dataKey } = request;

  yield put({ type: DOCUMENT_PENDING, dataKey });

  try {
    const { data: blob } = yield call(axios, {
      url: `/file/${dataKey}`,
      method: 'get',
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    });

    const { data: fileInfo } = yield call(axios, {
      url: `/file/?in(id,(${encodeRqlString(dataKey)}))`,
      method: 'get',
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    });

    const {
      id,
      links,
      metadata
    } = get(fileInfo, 0, {});

    const file = {
      blob,
      id,
      links,
      metadata,
      mimeType: get(metadata, 'mime')
    };

    yield put({ type: DOCUMENT_FULFILLED, dataKey, payload: file });

    return file;
  } catch (error) {
    yield put({ type: DOCUMENT_REJECTED, dataKey, error });

    return error;
  }
}
