import { rootSaga as customerIdentificationSaga } from '@evoja-web/customer-identification';
import { rootSaga as customerWorkguideSearchRootSaga } from '@evoja-web/customer-workguide-search';
import { rootSaga as addressDogRootSaga } from '@evoja-web/react-address-dog-search';
import { rootSaga as aisDocumentSigningRootSaga } from '@evoja-web/react-ais-document-signing';
import { rootSaga as bootstrapSagas } from '@evoja-web/react-bootstrapper';
import { rootSaga as documentPreviewRootSaga } from '@evoja-web/react-document-preview';
import { all } from 'redux-saga/effects';
import { loginRootSaga, logoutRootSaga } from '@evoja-web/react-login';
import { rootSaga as entityCodeRootSaga } from '@evoja-web/entity-code';
import { rootSaga as textblockRootSaga } from '@evoja-web/react-core-textblock';
import { rootSaga as fileUploadRootSaga } from '@evoja-web/react-file-upload';
import { rootSaga as clientSettingRootSaga } from '@evoja-web/core-clientsetting';
import { rootSaga as gamificationRootSaga } from '@evoja-web/gamificator';
import { rootSaga as applicationReleaseRootSaga } from '@evoja-web/react-application-release';

// Import Entris Sagas
import {
  entrisActivitySagas,
  entrisBankInformationSagas,
  entrisContactPersonSagas,
  entrisCustomerSagas,
  entrisDashboardSagas, entrisEmailSagas,
  entrisHolidaysSagas, entrisInstitutesSagas,
  entrisMaritalStatusCodesSagas,
  entrisPledgeLetterSagas,
  entrisProvisionLeadDetailSagas,
  entrisProvisionLeadsSagas,
  entrisStornoDeleteWorkguide,
  entrisUploadCollateralPDFSaga,
  entrisWorkguideSagas
  // eslint-disable-next-line
} from '@evoja-web/entris-portal-shared';

import { rootSaga as accountRootSaga } from '../packages/Account/index';
import { rootSaga as activityRootSaga } from '../packages/Activity/index';
import { rootSaga as addressRootSaga } from '../packages/Address/index';
import { rootSaga as airlockRootSaga } from '../packages/Airlock/index';
import { rootSaga as bpfCmsRootSaga } from '../packages/BpfCms/index';
import { rootSaga as bpfDocumentRootSaga } from '../packages/BpfDocument/index';
import { rootSaga as bpfOnboardingRootSaga } from '../packages/BpfOnboarding/index';
import { rootSaga as bpfRootSaga } from '../packages/Bpf/index';
import { rootSaga as bpfSecuritiesAccountRootSaga } from '../packages/BpfSecuritiesAccount/index';
import { rootSaga as calendarRootSaga } from '../packages/Calendar/index';
import { rootSaga as cardRootSaga } from '../packages/Card/index';
import { rootSaga as compactableRootSaga } from '../packages/Compactable/index';
import { rootSaga as consultationFormRootSaga } from '../packages/ConsultationForm/index';
import { rootSaga as contractRootSaga } from '../packages/Contract/index';
import { rootSaga as customerBaseDataRootSaga } from '../packages/CustomerBaseData/index';
import { rootSaga as customerDetailRootSaga } from '../packages/CustomerDetail/index';
import { rootSaga as customerOverviewRootSaga } from '../packages/CustomerOverview/index';
import { rootSaga as customerRootSaga } from '../packages/Customer/index';
import { rootSaga as dashboardRootSaga } from '../packages/Dashboard/index';
import { rootSaga as eDossierRootSaga } from '../packages/EDossier/index';
import { rootSaga as enforcementRootSaga } from '../packages/Enforcement/index';
import { rootSaga as environmentRootSaga } from '../packages/Environment/index';
import { rootSaga as errorRootSaga } from '../packages/Error/index';
import { rootSaga as externalLinkRootSaga } from '../packages/ExternalLinks/index';
import { rootSaga as featureFlagRootSaga } from '../packages/FeatureFlag/index';
import { rootSaga as fidentityRootSaga } from '../packages/Fidentity/index';
import { rootSaga as fileRootSaga } from '../packages/File/index';
import { rootSaga as financingRootSaga } from '../packages/Financing/index';
import { rootSaga as finnovaRootSaga } from '../packages/Finnova/index';
import { rootSaga as customerPopoverRootSaga } from '../packages/InfoPopover/index';
import { rootSaga as interestRootSaga } from '../packages/Interest/index';
import { rootSaga as leadRootSaga } from '../packages/Lead/index';
import { rootSaga as messengerRootSaga } from '../packages/Messenger/index';
import { rootSaga as modalRootSaga } from '../packages/Modal/index';
import { rootSaga as newsletterRootSaga } from '../packages/Newsletter/index';
import { rootSaga as periodicUpdateRootSaga } from '../packages/PeriodicUpdate/index';
import { rootSaga as phoneCallRootSaga } from '../packages/PhoneCall/index';
import { rootSaga as powerOfAttorneyRootSaga } from '../packages/PowerOfAttorney/index';
import { rootSaga as productGroupRootSaga } from '../packages/ProductGroup/index';
import { rootSaga as provisonRootSaga } from '../packages/Provision/index';
import { rootSaga as quickFilterRootSaga } from '../packages/QuickFilter/index';
import { rootSaga as referencingRootSaga } from '../packages/Referencing/index';
import { rootSaga as relationRootSaga } from '../packages/Relation/index';
import { rootSaga as releaseNoteSaga } from '../packages/ReleaseNote/index';
import { rootSaga as setAdvisorRootSaga } from '../packages/SetAdvisor/index';
import { rootSaga as spsSaga } from '../packages/Sps/index';
import { rootSaga as standingOrderRootSaga } from '../packages/StandingOrder/index';
import { rootSaga as stockPriceRootSaga } from '../packages/StockPrice/index';
import { rootSaga as successorPlanningRootSaga } from '../packages/SuccessorPlanning/index';
import { rootSaga as uiNoteRootSaga } from '../packages/UiNote/index';
import { rootSaga as vaultRootSaga } from '../packages/Vaults/index';
import { rootSaga as visecaRootSaga } from '../packages/Viseca/index';
import { rootSaga as workbenchRootSaga } from '../packages/Workbench/index';
import { rootSaga as workguideRootSaga } from '../packages/Workguide/index';

import aclSagas from './Acl';
import baseContractSagas from './BaseContract';
import consultantSearchSagas from './ConsultantSearch';
import customerSearchSagas from './CustomerSearch';
import dataLoadStateSagas from './DataLoadState';
import errorSagas from './Error';
import estimationProtocolSagas from './EstimationProtocol';
import eventStatusSagas from './EventStatus';
import financingEstimationSagas from './FinancingEstimation';
import guidedTourSagas from './GuidedTour';
import notificationSaga from './Notification';
import setAdvisorSagas from './SetAdvisor';
import userSettingsSagas from './UserSettings';
import userSwitchSagas from './UserSwitch';
import usertrackingSagas from './UserTracking';

export default function* rootSaga() {
  yield all([
    accountRootSaga(),
    aclSagas(),
    activityRootSaga(),
    addressDogRootSaga(),
    addressRootSaga(),
    airlockRootSaga(),
    aisDocumentSigningRootSaga(),
    applicationReleaseRootSaga(),
    baseContractSagas(),
    bootstrapSagas(),
    bpfCmsRootSaga(),
    bpfDocumentRootSaga(),
    bpfOnboardingRootSaga(),
    bpfRootSaga(),
    bpfSecuritiesAccountRootSaga(),
    calendarRootSaga(),
    cardRootSaga(),
    clientSettingRootSaga(),
    compactableRootSaga(),
    consultantSearchSagas(),
    consultationFormRootSaga(),
    contractRootSaga(),
    customerBaseDataRootSaga(),
    customerDetailRootSaga(),
    customerIdentificationSaga(),
    customerOverviewRootSaga(),
    customerPopoverRootSaga(),
    customerRootSaga(),
    customerSearchSagas(),
    customerWorkguideSearchRootSaga(),
    dashboardRootSaga(),
    dataLoadStateSagas(),
    documentPreviewRootSaga(),
    eDossierRootSaga(),
    enforcementRootSaga(),
    entityCodeRootSaga({ cacheCodeGroups: true, cacheCodeMappings: true }),
    environmentRootSaga(),
    errorRootSaga(),
    errorSagas(),
    externalLinkRootSaga(),
    estimationProtocolSagas(),
    eventStatusSagas(),
    featureFlagRootSaga(),
    fidentityRootSaga(),
    fileRootSaga(),
    fileUploadRootSaga(),
    financingEstimationSagas(),
    financingRootSaga(),
    finnovaRootSaga(),
    gamificationRootSaga(),
    guidedTourSagas(),
    interestRootSaga(),
    leadRootSaga(),
    loginRootSaga(),
    logoutRootSaga(),
    messengerRootSaga(),
    modalRootSaga(),
    newsletterRootSaga(),
    notificationSaga(),
    periodicUpdateRootSaga(),
    phoneCallRootSaga(),
    powerOfAttorneyRootSaga(),
    productGroupRootSaga(),
    provisonRootSaga(),
    quickFilterRootSaga(),
    referencingRootSaga(),
    relationRootSaga(),
    releaseNoteSaga(),
    setAdvisorRootSaga(),
    setAdvisorSagas(),
    spsSaga(),
    standingOrderRootSaga(),
    stockPriceRootSaga(),
    successorPlanningRootSaga(),
    textblockRootSaga(),
    uiNoteRootSaga(),
    userSettingsSagas(),
    userSwitchSagas(),
    usertrackingSagas(),
    vaultRootSaga(),
    visecaRootSaga(),
    workbenchRootSaga(),
    workguideRootSaga(),
    // entris sagas/
    entrisActivitySagas(),
    entrisBankInformationSagas(),
    entrisCustomerSagas(),
    entrisContactPersonSagas(),
    entrisHolidaysSagas(),
    entrisWorkguideSagas(),
    entrisEmailSagas(),
    entrisDashboardSagas(),
    entrisMaritalStatusCodesSagas(),
    entrisInstitutesSagas(),
    entrisPledgeLetterSagas(),
    entrisProvisionLeadsSagas(),
    entrisProvisionLeadDetailSagas(),
    entrisUploadCollateralPDFSaga(),
    entrisStornoDeleteWorkguide()
  ]);
}
