import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get, isNil } from 'lodash';
import parseLinkHeader from 'parse-link-header';
import { getIdFromGravitonUrl } from '@evoja-web/react-document-preview';
import { pollSync, getIdFromEventStatusUri } from '@evoja-web/event-status';

import actions from '../actions/Actions';
import getDocument from './getDocument';

const {
  MERGE_DOCUMENTS_PENDING,
  MERGE_DOCUMENTS_FULFILLED,
  MERGE_DOCUMENTS_REJECTED
} = actions;

export default function* mergeDocuments(request) {
  const { fileIds, returnFile = true, withDocumentValidation = false } = request;

  yield put({ type: MERGE_DOCUMENTS_PENDING });

  try {
    const fileIdsString = `${fileIds.join(',')}`;

    // mergeAndValidateDocuments liefert aktuell noch keine FIle id in den Informationen zurück, wie es bei mergeDocuemnts gemacht wird.
    const fileActions = withDocumentValidation ? ['mergeAndValidateDocuments'] : ['mergeDocuments'];
    // const fileActions = ['mergeDocuments'];

    const fileUrl = 'file/action';
    const params = {
      fileId: fileIdsString,
      actions: fileActions,
      additionalProperties: []
    };

    const eventStatus = yield call(axios, {
      url: fileUrl,
      method: 'post',
      data: params
    });

    const linkHeaders = parseLinkHeader(get(eventStatus, 'headers.link'));
    const eventStatusId = getIdFromEventStatusUri(get(linkHeaders, 'eventStatus.url'));

    const result = yield call(pollSync, {
      dataKey: eventStatusId,
      maxTries: 15,
      suppressToast: true,
      workers: [{ id: 'ais' }]
    });

    const informations = get(result, 'information');
    const fileInfo = informations.find((info) => info.content === 'mergedDocument');
    const filePath = get(fileInfo, '$ref');

    const file = {
      fileId: getIdFromGravitonUrl(filePath),
      link: get(fileInfo, '$ref')
    };

    if (returnFile && !isNil(get(file, 'fileId'))) {
      const fileFromServer = yield call(getDocument, { dataKey: get(file, 'fileId') });
      file.file = get(fileFromServer, 'blob');
    }

    yield put({ type: MERGE_DOCUMENTS_FULFILLED, payload: file });

    return file;
  } catch (error) {
    yield put({ type: MERGE_DOCUMENTS_REJECTED, error });

    return error;
  }
}
