import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page, Text, View, Image, Font } from '@react-pdf/renderer';
import { extractCustomerDataFromPDFProps } from '@evoja-web/entris-portal-shared';
import { get, has, isNil } from 'lodash';
import moment from 'moment';
import { baseStyles } from '../styles';
import makeT from '../../../lib/Utils/makeT';
import { customerBankInfoBaseStyles } from './DefaultLayout';
import { footerBaseStyles } from '../components/Footer';
import parseEditorHtmlStringToPdfElements from '../../../lib/Pdf/Utils/parseEditorHtmlStringToPdfElements';
import checkboxUnchecked from '../../../images/pdf/checkbox-unchecked.jpg';
import checkboxChecked from '../../../images/pdf/checkbox-checked.jpg';
import valiantLogo from '../../../images/pdf/Valiant_RGB.jpg';
import valiantAgbQrCode from '../../../images/pdf/valiant_agb_qr_code.jpg';
import { checkboxes } from '../../../packages/Sps/lib/spsCoverLetterCheckboxes';
import ArialBoldItalic from '../Fonts/Arial/ARIALBOLDITALICMT.OTF';
import ArialBold from '../Fonts/Arial/ARIALBOLDMT.OTF';
import Arial from '../Fonts/Arial/arialmt.ttf';
import ArialItalic from '../Fonts/Arial/ARIALMTITALIC.OTF';
import spsCoverLetterStyle from '../spsStyles';

const headerStyles = {
  image: {
    maxWidth: 140
  },
  container: {
    ...baseStyles.container,
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    height: '80pt'
  }
};

function SpsCoverLetter({
  customer, formData, consultant, intl, language
}) {
  Font.register({ family: 'Arial MT',
    fonts: [
      { src: Arial },
      { src: ArialBold, fontStyle: 'normal', fontWeight: 'bold' },
      { src: ArialItalic, fontStyle: 'italic', fontWeight: 'normal' },
      { src: ArialBoldItalic, fontStyle: 'italic', fontWeight: 'bold' },
    ] });

  const { t } = makeT(intl);
  const isDoublePartner = get(customer, 'mainType.id') === 'mainType-2'

  const location = get(customer, 'consultant.location');
  const employeeId = get(consultant, 'employeeId', '').toLowerCase();
  const { kubeContactEmail, kubeContactName, kubeContactPhone } = extractCustomerDataFromPDFProps(customer);
  const consultantAddress = () => {
    const location = get(customer, 'consultant.location');

    if (customer.consultant) {
      // eslint-disable-next-line max-len
      return `Valiant Bank AG, ${get(location, 'street')}, ${get(location, 'zip')} ${get(location, 'city')} \n ${t('Pdf.Phone')} 031 320 91 11, valiant.ch`;
      // eslint-disable-next-line max-len
    }
    return 'Valiant Bank AG, Mattenstrasse 8, Postfach, 3073 Gümligen \n Telefon 031 310 63 08, Telefax 031 320 91 12, valiant.ch';
  };

  // eslint-disable-next-line max-len
  const customerAddress = (customer, address) => `${customerSalutationAndName(customer)}\n${get(address, 'street')}\n${get(address, 'zipCode')} ${get(address, 'city')}`;

  const customerSalutationAndName = (customer) => {
    const salutationNameString = `${get(customer, `salutation.text.${language}`)} ${get(customer, 'firstName')} ${get(customer, 'lastName')}`;

    if(isDoublePartner) {
      const partnerSalutationNameString = `${get(customer, `salutationPartner.text.${language}`)} ${get(customer, 'firstNamePartner')} ${get(customer, 'lastNamePartner')}`
      return `${salutationNameString} \n ${partnerSalutationNameString}`;
    }
    return salutationNameString;
  }

  // Get Description Pdf Elements if set
  const descriptionPdfComponent = has(formData, 'descriptionHtmlElement')
    ? parseEditorHtmlStringToPdfElements(get(formData, 'descriptionHtmlElement'))
    : undefined;

  const getCheckboxItems = (checkboxList) => {
    return checkboxList.map((checkbox) => {
      const checkboxImg = get(formData, 'checkboxes', []).includes(checkbox) ? checkboxChecked : checkboxUnchecked;
      return (
        <Text style={{ ...spsCoverLetterStyle.text, paddingBottom: 2 }}>
          <Image src={checkboxImg} />
          <Text style={{ fontFamily: 'Arial MT' }}>
            {' '}
            {t(`BpfDocument.SendByPost.CreateCoverLetterForm.Checkboxes.${checkbox}`)}
          </Text>
        </Text>
      );
    });
  };

  return (
    <Document>
      <Page size="A4" wrap style={{ ...baseStyles.page, paddingBottom: 100, paddingHorizontal: 90, fontFamily: 'Arial MT' }}>

        <View style={headerStyles.container} fixed>
          <Image style={{ maxHeight: 35 }} src={valiantLogo} />
        </View>
        <View style={{ ...customerBankInfoBaseStyles.container, marginBottom: 50, marginTop: '63', fontFamily: 'Arial MT' }}>
          <View style={{ ...customerBankInfoBaseStyles.childContainer, marginLeft: '252', fontFamily: 'Arial MT' }}>
            <Text style={{ ...spsCoverLetterStyle.text, fontFamily: 'Arial MT' }}>
              {`${customerAddress(customer, get(formData, 'address'))}`}
            </Text>
          </View>
        </View>
        <Text style={{ fontFamily: 'Arial MT' }}>{'\n'}</Text>
        <Text style={{ fontFamily: 'Arial MT' }}>{'\n'}</Text>
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ marginTop: 8, flexDirection: 'row' }}>
            <Text style={{ fontSize: 10, fontFamily: 'Arial MT' }}>
              {`${get(location, 'city')}, ${moment().format('LL')}/${employeeId}`}
            </Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row', marginTop: 8, width: 'auto' }}>
            <View style={{ display: 'flex', textAlign: 'left', alignItems: 'flex-end' }}>
              <Text style={{ ...spsCoverLetterStyle.text, fontSize: 8.5 }}>{`${t('Pdf.ReferencePerson')}:`}</Text>
              <Text style={{ ...spsCoverLetterStyle.text, fontSize: 8.5 }}>{kubeContactName}</Text>
              <Text style={{ ...spsCoverLetterStyle.text, fontSize: 8.5 }}>{kubeContactEmail}</Text>
              <Text style={{ ...spsCoverLetterStyle.text, fontSize: 8.5 }}>{`${t('Pdf.Phone')}: ${kubeContactPhone}`}</Text>
            </View>

          </View>
        </View>

        <View>
          {/* <Text>{'\n'}</Text> */}

          {get(formData, 'checkboxes.length', 0) > 0 && (
            <View style={{ marginLeft: 7 }}>
              <Text>{'\n'}</Text>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                  {getCheckboxItems(checkboxes.slice(0, 6))}
                </View>
                <View style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                  {getCheckboxItems(checkboxes.slice(6))}
                </View>
              </View>
            </View>
          )}
          <Text>{'\n'}</Text>

          <View style={{ marginTop: 8, flexDirection: 'row' }}>
            <Text style={{ ...spsCoverLetterStyle.textBold, fontSize: 10 }}>
              {get(formData, 'subject', '')}
            </Text>
          </View>

          <View wrap>
            {
              !isNil(descriptionPdfComponent) && (
                descriptionPdfComponent.map((el) => el)
              )
            }
          </View>

          <View style={{ marginTop: 14, flexDirection: 'row' }}>
            <Text style={{ fontSize: 10, fontFamily: 'Arial MT' }}>
              {t('Pdf.General.Greetings')}
            </Text>
          </View>
          <Text style={spsCoverLetterStyle.textBold}>{t('Pdf.General.ValiantBank')}</Text>
          <Text>{'\n'}</Text>
          <View style={{ marginTop: 8, flexDirection: 'row' }}>
            <Text style={{ fontSize: 10, fontFamily: 'Arial MT' }}>
              {t('Pdf.General.DocumentWithoutSignature')}
            </Text>
          </View>
        </View>
        <View style={{ ...footerBaseStyles.container, fontFamily: 'Arial MT', marginBottom: '25px' }} fixed>
          <View style={{ width: '60%', display: 'flex', justifyContent: 'center' }}>
            <Text>
              {t(`BpfDocument.SendByPost.CreateCoverLetterForm.ValiantAgbDescription`)}
            </Text>
          </View>
          <View style={{ width: '9%', display: 'flex', alignItems: 'flex-end'}}>
            <Image src={valiantAgbQrCode} style={{ width:'35px' }} />
          </View>
        </View>

        <View style={{ ...footerBaseStyles.container, fontFamily: 'Arial MT' }} fixed>
          <View style={footerBaseStyles.leftContainer}>
            <Text>{consultantAddress()}</Text>
          </View>
          <View style={{ width: '20%', textAlign: 'left', fontFamily: 'Arial MT' }}>
            <Text>BC 6300, PC 30-38112-0 </Text>
            <Text>CHE-116-268-922 MWST</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

SpsCoverLetter.propTypes = {
  intl: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  language: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  consultant: PropTypes.object.isRequired
};

export default SpsCoverLetter;
