import { put, call } from 'redux-saga/effects';
import { get, isEmpty, isNil } from 'lodash';

import { createIntl } from 'react-intl';
import actions from '../../actions/Actions';
import SpsCoverLetter from '../../../../components/Pdf/Letters/SpsCoverLetter';
import { generatePdf } from '../../../../lib/Pdf/Utils';

// Intl stuff
import messages from '../../../../intl/messages';
import { flattenMessages } from '../../../../utils';
import prepareCoverLetterDescription from '../../utils/prepareCoverLetterDescription';
import uploadFiles from '../uploadFiles';

const {
  CREATE_COVER_LETTER_WORKGUIDE_FORM_SAVE_PENDING,
  CREATE_COVER_LETTER_WORKGUIDE_FORM_SAVE_FULFILLED,
  CREATE_COVER_LETTER_WORKGUIDE_FORM_SAVE_REJECTED
} = actions;

export default function* saveForm(request) {
  const { customer, data, consultant } = request;

  yield put({ type: CREATE_COVER_LETTER_WORKGUIDE_FORM_SAVE_PENDING });

  try {
    // Prepare Description for Cover Letter
    const description = get(data, 'description');
    if (!isNil(description) && !isEmpty(description)) {
      data.descriptionHtmlElement = prepareCoverLetterDescription({ description });
    }

    const language = get(customer, 'languageIso', 'de');
    const intl = createIntl({
      locale: language,
      messages: flattenMessages(messages[language])
    });

    // Cover Letter generation
    const spsCoverLetter = SpsCoverLetter({ customer, formData: data, language, intl, consultant });

    const spsCoverLetterResult = yield call(generatePdf, spsCoverLetter);

    const fileToUpload = { file: get(spsCoverLetterResult, 'blob') };
    // TODO Remove (only for Test in Code)
    // const url = `/preview/pdf/${window.btoa(spsCoverLetterResult.url)}`;
    // window.open(url, '_blank');

    // Upload generated Files
    const fileId = yield call(uploadFiles, [fileToUpload]);

    yield put({ type: CREATE_COVER_LETTER_WORKGUIDE_FORM_SAVE_FULFILLED, payload: data });

    return data;
  } catch (error) {
    yield put({ type: CREATE_COVER_LETTER_WORKGUIDE_FORM_SAVE_REJECTED, error });

    return error;
  }
}
