import { call, put } from 'redux-saga/effects';

import { get } from 'lodash';
import { uploadSaga } from '@evoja-web/react-file-upload';
import consultationFormActions from '../actions/Actions';
import archive from '../../EDossier/sagas/archive';

const {
  CONSULTATION_FORM_EDOSSIER_UPLOAD_PENDING,
  CONSULTATION_FORM_EDOSSIER_UPLOAD_FULFILLED,
  CONSULTATION_FORM_EDOSSIER_UPLOAD_REJECTED
} = consultationFormActions;

export default function* uploadToEdossier(request) {
  yield put({ type: CONSULTATION_FORM_EDOSSIER_UPLOAD_PENDING });

  try {
    const { customerId, data } = request;
    const dataKey = get(data, 'id');

    yield call(uploadSaga, { dataKey, ...data });

    const uploadedFile = yield call(archive, { dataKey, customerId, ...data });

    if (uploadedFile instanceof Error) {
      throw uploadedFile;
    }

    yield put({ type: CONSULTATION_FORM_EDOSSIER_UPLOAD_FULFILLED, payload: uploadedFile });

    return uploadedFile;
  } catch (error) {
    yield put({ type: CONSULTATION_FORM_EDOSSIER_UPLOAD_REJECTED, error });

    return error;
  }
}
