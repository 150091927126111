import { takeEvery } from 'redux-saga/effects';

import fileActions from '../actions/Actions';
import mergeDocuments from './mergeDocuments';
import getDocument from './getDocument';
import printDocuments from './printDocuments';
import getProfilePicture from './getProfilePicture';

const {
  MERGE_DOCUMENTS_REQUEST, DOCUMENT_REQUEST, PRINT_DOCUMENTS_REQUEST, PROFILE_PICTURE_REQUEST
} = fileActions;

export default function* fileRootSaga() {
  yield takeEvery(MERGE_DOCUMENTS_REQUEST, mergeDocuments);
  yield takeEvery(DOCUMENT_REQUEST, getDocument);
  yield takeEvery(PRINT_DOCUMENTS_REQUEST, printDocuments);
  yield takeEvery(PROFILE_PICTURE_REQUEST, getProfilePicture);
}
