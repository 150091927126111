import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get, isNil } from 'lodash';

import actions from '../actions/Actions';
import { ReduxSagaGeneralError } from '../../Error/index';

const {
  PROFILE_PICTURE_PENDING,
  PROFILE_PICTURE_FULFILLED,
  PROFILE_PICTURE_REJECTED
} = actions;

export default function* getProfilePicture(request) {
  const { dataKey } = request;

  yield put({ type: PROFILE_PICTURE_PENDING, dataKey });

  try {
    if (isNil(dataKey)) {
      const e = new ReduxSagaGeneralError(undefined, { cause: new Error('Cannot make Profile Picture Request, because User has no userId set') });
      e.setTargetActive('sentry', false);

      throw e;
    }

    const { data: result } = yield call(axios, {
      url: `/graph/v1.0/users/${dataKey}/photo/$value`,
      method: 'get',
      responseType: 'arraybuffer',
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    });

    // Convert the response data (arraybuffer) into a Blob
    const imageBlob = new Blob([result], { type: 'image/jpg' });

    yield put({ type: PROFILE_PICTURE_FULFILLED, dataKey, payload: imageBlob });

    return imageBlob;
  } catch (error) {
    if (get(error, 'response.status') === 404) {
      yield put({ type: PROFILE_PICTURE_FULFILLED, dataKey, payload: undefined });
    } else {
      yield put({ type: PROFILE_PICTURE_REJECTED, dataKey, error, suppressToast: true });
    }

    return error;
  }
}
