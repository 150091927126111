import { call, put, all } from 'redux-saga/effects';

import axios from 'axios';
import consultantInsightsAction from '../actions/ConsultantInsights';

const {
  FETCH_CONSULTANT_INSIGHTS_PENDING,
  FETCH_CONSULTANT_INSIGHTS_FULFILLED,
  FETCH_CONSULTANT_INSIGHTS_REJECTED
} = consultantInsightsAction;

export default function* getConsultantInsights({ consultantId }) {
  yield put({ type: FETCH_CONSULTANT_INSIGHTS_PENDING });

  try {
    const urls = [
      `/person/consultant/inbound-transactions/?eq(consultantId,string:${consultantId})`,
      `/person/consultant/outbound-transactions/?eq(consultantId,string:${consultantId})`,
      `/person/consultant/investment-potential/?eq(consultantId,string:${consultantId})`,
      `/person/consultant/customer-profit/?eq(consultantId,string:${consultantId})`,
      `/person/consultant/customer-mutations/?eq(consultantId,string:${consultantId})`,
      `/person/consultant/provision-investment-potential/?eq(consultantId,string:${consultantId})`,
      `/person/consultant/account-overdraft/?eq(consultantId,string:${consultantId})`,
      `/person/consultant/deposit-transactions/?eq(consultantId,string:${consultantId})`,
      `/basic/product/mutation/?eq(consultantId,string:${consultantId})sort(-mutationDate)`,
      `/person/consultant/consultant-volume/?eq(consultantId,string:${consultantId})`,
      `/person/consultant/consultant-profit/?eq(consultantId,string:${consultantId})`
    ];

    const [
      { data: inboundTransactions },
      { data: outboundTransactions },
      { data: investmentPotential },
      { data: customerProfit },
      { data: customerMutations },
      { data: provisionInvestmentPotential },
      { data: accountOverdraft },
      { data: depositTransactions },
      { data: productMutations },
      { data: consultantVolume },
      { data: consultantProfit }
    ] = yield all(urls.map((url) => call(axios.get, url)));
    const payload = {
      inboundTransactions,
      outboundTransactions,
      investmentPotential,
      customerProfit,
      customerMutations,
      provisionInvestmentPotential,
      accountOverdraft,
      depositTransactions,
      productMutations,
      consultantVolume,
      consultantProfit
    };

    yield put({ type: FETCH_CONSULTANT_INSIGHTS_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: FETCH_CONSULTANT_INSIGHTS_REJECTED, error });

    return error;
  }
}
