import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, noop, isArray } from 'lodash';
import { Row, Col, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withAcl } from '@evoja-web/client-acl';
import { withCodeGroups } from '@evoja-web/entity-code';
import { Alert, AlertTitle, AlertContent } from '@evoja-web/react-layout-components';

import { ProcessComments } from '../../../Bpf/index';
import bpfOnboardingActions from '../../actions/Actions';
import CustomerInfo from '../../components/Workguide/Common/CustomerInfo';
import { withWorkguideInstanceData } from '../../../Workguide/index';

class WorkguideCheckOnboarding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comments: []
    };

    this.onCommentsChange = this.onCommentsChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onCommentsChange(comments) {
    this.setState({ comments });
  }

  onSubmit(accept = false) {
    const { comments } = this.state;
    const {
      acl,
      bpfOnboardingActions,
      bpfTask,
      redirect
    } = this.props;

    bpfOnboardingActions.completeTaskRequest({
      dataKey: get(bpfTask, 'data.task.id'),
      skipUserCheck: acl.isAllowed('Bpf.SkipUserCheck'),
      variables: {
        onboardingAccepted: accept,
        comments
      }
    });

    redirect();
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      bpfTask,
      codes,
      customer,
      environment,
      language,
      redirect,
      session
    } = this.props;

    const comments = !isArray(get(bpfTask, 'data.process.comments', []))
      ? []
      : get(bpfTask, 'data.process.comments', []);

    return (
      <div>
        <Row>
          <Col lg={12}>
            <h5>
              <FormattedMessage id="Onboarding.Process.Title" />
            </h5>
            <hr />
          </Col>
        </Row>

        <p>
          <FormattedMessage id="Onboarding.Process.Descritption" />
        </p>

        <Checkbox
          id="duplicateCheck"
          label={<FormattedMessage id="Onboarding.Process.DuplicatesCheck" />}
          value={get(bpfTask, 'data.process.duplicateCheck', false)}
          disable
        />

        <Checkbox
          id="pepCheck"
          label={<FormattedMessage id="Onboarding.Process.PepCheck" />}
          value={!get(bpfTask, 'data.process.pepCheck', false)}
          disable
        />

        <Checkbox
          id="domicileCH"
          label={<FormattedMessage id="Onboarding.Process.DomicileCH" />}
          value={get(bpfTask, 'data.process.domicileCH', false)}
          disable
        />

        <Checkbox
          id="beneficialOwner"
          label={<FormattedMessage id="Onboarding.Process.BeneficialOwner" />}
          value={get(bpfTask, 'data.process.customerInformation.legalInformation.beneficialOwner', false)}
          disable
        />

        <Checkbox
          id="AiaFatca"
          label={<FormattedMessage id="Onboarding.Process.AiaFatca" />}
          value={
            get(bpfTask, 'data.process.customerInformation.legalInformation.aia', false)
            || get(bpfTask, 'data.process.customerInformation.legalInformation.fatca', false)
          }
          disable
        />

        <Checkbox
          id="beneficialOwner"
          label={<FormattedMessage id="Onboarding.Process.IdentificationInformation" />}
          value={get(bpfTask, 'data.process.clientIdentification.identificationStatus') === 'green'}
          disable
        />

        <CustomerInfo bpfTask={bpfTask} codes={codes} language={language} />

        <Row>
          <Col lg={12}>
            <h5>
              <FormattedMessage id="Onboarding.Documents.Title" />
            </h5>
            <hr />
          </Col>
        </Row>

        <div style={{ paddingBottom: '15px' }}>
          <a
            href={get(bpfTask, 'data.process.camUserInformation.url')}
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="Onboarding.CamLink" />
          </a>
        </div>

        <Row style={{ paddingBottom: '2rem' }}>
          <Col lg={12} md={12}>
            <a
              className="text-primary"
              href={`${get(environment, 'config.edossier_url')}${get(customer, 'customerNumber')}`}
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage id="Onboarding.CustomerInfo.OpenEDossier" />
            </a>
          </Col>
        </Row>

        <Alert type="warning" style={{ width: '100%' }}>
          <AlertContent>
            <FormattedMessage id="Onboarding.Warning.Check" />
          </AlertContent>
        </Alert>

        <Row style={{ paddingBottom: '20px' }}>
          <Col lg={12}>
            <ProcessComments
              comments={comments}
              onChange={this.onCommentsChange}
              readOnly={false}
              session={session}
            />
          </Col>
        </Row>

        <Row>
          <Col lg="auto" md="auto" sm="auto">
            <Button
              color="primary"
              disabled={get(bpfTask, 'data.erroneous', false)}
              onClick={() => this.onSubmit(true)}
            >
              <FormattedMessage id="Activity.Form.Submit" />
            </Button>

            <span style={{ paddingRight: '20px' }} />

            <Button
              color="primary"
              disabled={get(bpfTask, 'data.erroneous', false)}
              onClick={() => this.onSubmit(false)}
              outline
            >
              <FormattedMessage id="Activity.Form.Reject" />
            </Button>

            <span style={{ paddingRight: '20px' }} />

            <Button color="primary" onClick={redirect} outline>
              <FormattedMessage id="General.Button.Cancel" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

WorkguideCheckOnboarding.propTypes = {
  acl: PropTypes.object.isRequired,
  bpfOnboardingActions: PropTypes.object.isRequired,
  bpfTask: PropTypes.object.isRequired,
  codes: PropTypes.object,
  customer: PropTypes.object,
  environment: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  redirect: PropTypes.func,
  session: PropTypes.object
};

WorkguideCheckOnboarding.defaultProps = {
  codes: { groups: {} },
  customer: undefined,
  redirect: noop,
  session: undefined,
};

function isRequesting(state, ownProps) {
  return get(ownProps, 'codes.requesting', false);
}

function mapStateToProps(state) {
  return {
    environment: state.environment,
    language: state.login.language,
    requesting: isRequesting(state),
    session: state.login.session
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bpfOnboardingActions: bindActionCreators(bpfOnboardingActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  withCodeGroups({ groups: ['documentIdType', 'customerCheckType', 'country'] })(
    connect(mapStateToProps, mapDispatchToProps)(
      withAcl()(
        injectIntl(WorkguideCheckOnboarding)
      )
    )
  )
);
