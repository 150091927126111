import { call, all } from 'redux-saga/effects';
import { get } from 'lodash';
import { upload } from '@evoja-web/react-file-upload/sagas';
import { v4 } from 'uuid';

export default function* uploadFiles (files) {
    try {
      const operations = files.map((item) => call(upload, { dataKey: v4(), file: get(item, 'file'), metadata: {} }));
      const result = yield all(operations);
  
      return result.map((file) => {
        const fileLocation = get(file, 'updateResult.headers.location');
        return fileLocation.replace(/\/file\//, '');
      });
  
    } catch (error) {
      const errorText = get(files, 'length', 0) > 1 ? 'Cover Letter' : 'Cover Letter and Reply Letter';
      console.error(`Could not save the ${errorText}. Error: `, error);
      throw new Error(`Could not save the ${errorText}.`);
    }
  };
