import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Bootstrap, BootstrapContext } from '@evoja-web/react-bootstrapper';

import DataError from './DataError';

import {
  bootstrapAclPermissions,
  bootstrapCompactables,
  bootstrapConsultants,
  bootstrapCustomerOverview,
  bootstrapHolidays,
  bootstrapProductGroups,
  bootstrapReleaseNotes,
  bootstrapUiNotesBanners,
  bootstrapUserRoles,
  bootstrapUserSettings,
  bootstrapWorkguides,
  bootstrapFeatureFlags,
  bootstrapGamification
} from '../../sagas/Bootstrap/index';
import bootstrapExternalLinks from '../../sagas/Bootstrap/getExternalLinks';

const commonContext = BootstrapContext()
  .required({ id: 'acl', saga: bootstrapAclPermissions })
  .required({ id: 'userRoles', saga: bootstrapUserRoles })
  .required({ id: 'userSettings', saga: bootstrapUserSettings })
  .required({ id: 'featureFlags', saga: bootstrapFeatureFlags })
  .lazy({ id: 'consultants', saga: bootstrapConsultants })
  // https://issue.swisscom.ch/browse/MAP-8324
  .lazy({ id: 'customerOverview', saga: bootstrapCustomerOverview })
  .lazy({ id: 'workguides', saga: bootstrapWorkguides })
  .lazy({ id: 'releaseNotes', saga: bootstrapReleaseNotes })
  .lazy({ id: 'uiNotesBanners', saga: bootstrapUiNotesBanners })
  .lazy({ id: 'productGroups', saga: bootstrapProductGroups })
  .lazy({ id: 'compactables', saga: bootstrapCompactables })
  .lazy({ id: 'gamification', saga: bootstrapGamification })
  .lazy({ id: 'externalLinks', saga: bootstrapExternalLinks });

const customerCallContext = BootstrapContext()
  .required({ key: 'userSettings', saga: bootstrapUserSettings })
  .lazy({ id: 'releaseNotes', saga: bootstrapReleaseNotes })
  .lazy({ id: 'uiNotesBanners', saga: bootstrapUiNotesBanners });

const activityContext = commonContext
  .clone()
  .removeLazy('consultants')
  .removeLazy('workguides')
  .required({ id: 'consultants', saga: bootstrapConsultants })
  .required({ id: 'workguides', saga: bootstrapWorkguides })
  .required({ id: 'holidays', saga: bootstrapHolidays });

const bootstrapContext = {
  default: {
    id: 'common',
    context: commonContext
  },
  '/call/:phoneNumber': {
    id: 'customerCall',
    context: customerCallContext
  },
  '/workguide/mailCallback/:activityId/:statusId': {
    id: 'workguideMailCallback',
    context: activityContext
  },
  '/activity/:activityId/:customerId?/:existingWorkguideId?': {
    id: 'activity',
    context: activityContext
  },
  '/provision/workguide/:activityId/:customerId?/:existingWorkguideId?': {
    id: 'activity',
    context: activityContext
  },
  '/edit/activity/:activityId': {
    id: 'editActivity',
    context: activityContext
  },
  '/reload/activity/:activityId': {
    id: 'reloadActivity',
    context: activityContext
  }
};

function getBootstrapContextByPath(path) {
  return get(bootstrapContext, path, get(bootstrapContext, 'default'));
}

const BootstrapRoute = React.memo(({
  children,
  errorComponent,
  path
}) => {
  const {
    id,
    context
  } = getBootstrapContextByPath(path);

  return (
    <Bootstrap
      key={id}
      errorComponent={errorComponent}
      id={id}
      context={context}
    >
      {children}
    </Bootstrap>
  );
});

BootstrapRoute.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string.isRequired,
  errorComponent: PropTypes.elementType
};

BootstrapRoute.defaultProps = {
  children: [],
  errorComponent: DataError
};

export default BootstrapRoute;
