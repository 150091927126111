import React from 'react';
import PropTypes from 'prop-types';
import { get, isArray, noop, some } from 'lodash';

import { SelectableList } from '../../../General/index';
import ListItem from './ListItem';

const CardList = React.memo(({
  id,
  language,
  cards,
  cardDetails,
  selected,
  disabled,
  onChange,
}) => {
  function getItemStyle({ id }) {
    const details = get(cardDetails, `${id}.data`, {});
    if (some(Object.values(details), (d) => d instanceof Error)) {
      return { color: ' #7e580e' };
    }
    return {};
  }

  const onChangeList = (id, value) => {
    // enrich card data with card details
    let data;
    if(isArray(value)){
      data = value.map((val) => {
        const details = get(cardDetails, `${get(val, 'id')}.data`, {});
        return { ...val, details }
      })
    } else {
      const details = get(cardDetails, `${get(value, 'id')}.data`, {});
      data = { ...value, details };
    }

    onChange(id, data);
  };

  return (
    <SelectableList
      id={id}
      language={language}
      listItemComponent={ListItem}
      listItemProps={{
        style: getItemStyle,
        cardDetails
      }}
      data={cards}
      selected={selected}
      disabled={disabled}
      onChange={onChangeList}
    />
  );
});

CardList.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  language: PropTypes.string,
  cards: PropTypes.array,
  selected: PropTypes.array,
  disabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.array
  ]),
  onChange: PropTypes.func,
  cardDetails: PropTypes.object
};

CardList.defaultProps = {
  language: 'de',
  cards: [],
  selected: [],
  disabled: false,
  onChange: noop,
  cardDetails: {}
};

export default CardList;
