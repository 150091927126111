import { Reducers } from '@evoja-web/redaction';

import { reducer as mergeDocumentsReducer } from './MergeDocuments';
import { reducer as documentReducer } from './Document';
import { reducer as printDocumentsReducer } from './PrintDocument';
import { reducer as profilePictureReducer } from './ProfilePicture';

export const reducers = Reducers([
  mergeDocumentsReducer,
  documentReducer,
  printDocumentsReducer,
  profilePictureReducer
]);

export default reducers.get();
