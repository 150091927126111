import { Reducers } from '@evoja-web/redaction';

import { reducer as createCoverLetterFormReducer } from './SendByPost/CreateCoverLetterForm';
import { reducer as sendDocumentsWithSpsReducer } from './SendByPost/SendDocumentsWithSps';
import { reducer as createCoverLetterWorkguideFormReducer } from './Workguide/CreateCoverLetterWorkguideForm';

export const reducers = Reducers([
  createCoverLetterFormReducer,
  sendDocumentsWithSpsReducer,
  createCoverLetterWorkguideFormReducer
]);

export default reducers.get();
