import { chain, get, isNil } from 'lodash';
import moment from 'moment';

/**
 * Overwrites for the base configuration (./LeadFormBase) when creating a new lead
 */
export default function LeadFormConfigCreate() {
  return {
    orderOrigin: {
      disabled: false,
      visible: true
    },
    customerTaskType: {
      disabled: function leadFormConfigCreateDisabled({ data }) {
        const orderOrigin = get(data, 'orderOrigin');

        return isNil(orderOrigin);
      },
      visible: true
    },
    orderId: {
      disabled: true,
      visible: false
    },
    mainConsultant: {
      disabled: false,
      visible: true
    },
    additionalEditor: {
      disabled: function leadFormConfigCreateDisabled({ acl }) {
        return !acl.isAllowed('Leads.Create.SetAdditionalEditor');
      }
    },
    dueDate: {
      disabled: false,
      validations: function leadFormConfigCreateValidations({ minDueDate = moment().startOf('day').format() }) {
        return {
          isValidDate: [],
          minDate: [minDueDate]
        };
      }
    },
    expireDate: {
      disabled: false,
      validations: function leadFormConfigCreateValidations({ minExpireDate = moment().startOf('day').format() }) {
        return {
          isValidDate: [],
          minDate: [minExpireDate]
        };
      }
    },
    customerTaskStatus: {
      disabled: false
    },
    relatedFiles: {
      disabled: false,
      visible: false
    },
    eDossierFiles: {
      disabled: false,
      visible: true
    },
    createdAt: {
      disabled: true,
      visible: false
    },
    note: {
      disabled: false
    },
    leadinstruction: {
      disabled: function leadFormConfigCreateDisabled({ acl }) {
        return !acl.isAllowed('Leads.Create.AllowOrderOriginAU');
      }
    },
    customerResponse: {
      disabled: true,
      visible: false
    },
    createFurther: {
      disabled: false,
      visible: function leadFormConfigCreateVisible({ data, leadQualification }) {
        // Create further checkbox is only visible if there is no post action configured
        return chain(leadQualification)
          .get('data', [])
          .find((q) => q.id === get(data, 'customerTaskType.id'))
          .get('postAction')
          .isNil()
          .value();
      }
    }
  };
}
